import { FC, useCallback, useEffect, useState } from "react";
import X2JS from "x2js";
import {
  fetchEngines,
  fetchMakes,
  fetchModels,
  fetchPartTypes,
  fetchYears,
} from "../../api/showMeTheParts";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../common/helpers";
import { Item, YearMakeModelTabProps } from "./types";
import {
  Wrapper,
  DropdownSectionStyled,
  SearchButtonStyled,
  ClearButtonStyled,
} from "./styled";

const defaultState = {
  value: "",
  label: "",
  isLoading: false,
  isError: false,
};

const YearMakeModelTab: FC<YearMakeModelTabProps> = ({
  selectorsConfig,
  setSearchTerm,
  licenseKey,
  databaseUrl,
  setError,
  partTypeAllOption,
  setCollapsed,
  partTypeEnabled,
  engineEbabled,
  handleClearSearchResult,
}) => {
  const x2js = new X2JS();
  const [year, setYear] = useState<Item>({
    value: getLocalStorageValue(SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME),
    label: getLocalStorageValue(SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME),
    isLoading: false,
    isError: false,
  });
  const [make, setMake] = useState<Item>({
    value: getLocalStorageValue(SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME),
    label: getLocalStorageValue(SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME),
    isLoading: false,
    isError: false,
  });
  const [model, setModel] = useState<Item>({
    value: getLocalStorageValue(SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME),
    label: getLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME
    ).trim(),
    isLoading: false,
    isError: false,
  });
  const [product, setProduct] = useState<Item>({
    value: partTypeEnabled
      ? getLocalStorageValue(SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME)
      : "",
    label: partTypeEnabled
      ? getLocalStorageValue(SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME)
      : "",
    isLoading: false,
    isError: false,
  });
  const [engine, setEngine] = useState<Item>({
    value: engineEbabled
      ? getLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME)
      : "",
    label: engineEbabled
      ? getLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME)
      : "",
    isLoading: false,
    isError: false,
  });

  const apiRequestError =
    year.isError ||
    make.isError ||
    model.isError ||
    product.isError ||
    engine.isError;

  useEffect(() => {
    if (apiRequestError) setError(true);
  }, [apiRequestError]);

  const getYears = useCallback(async () => {
    if (!licenseKey || !databaseUrl) return;
    setYear({ ...year, isLoading: true });
    const response = await fetchYears({ id: licenseKey, url: databaseUrl });
    if (response === undefined) {
      setYear({ ...year, isError: true });
    }
    const data: any = x2js?.xml2js(response?.data);
    setYear((prev) => ({ ...prev, isLoading: false }));
    return data?.ShowMeTheParts_Year?.year;
  }, [licenseKey, databaseUrl]);

  const getMakes = useCallback(async () => {
    if (!year.value || !licenseKey || !databaseUrl) return;
    setMake({ ...make, isLoading: true });
    const response = await fetchMakes({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
    });
    if (!response) {
      setMake({ ...make, isError: true });
    }
    const data: any = x2js?.xml2js(response?.data);
    setMake((prev) => ({ ...prev, isLoading: false }));
    return data?.ShowMeTheParts_Make?.make;
  }, [year.value]);

  const getModels = useCallback(async () => {
    if (!make.value || !licenseKey || !databaseUrl) return;
    setModel({ ...model, isLoading: true });
    const response = await fetchModels({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
      make: make.value,
    });
    if (!response) {
      setModel({ ...model, isError: true });
    }
    const data: any = x2js?.xml2js(response?.data);
    setModel((prev) => ({ ...prev, isLoading: false }));
    if (data?.ShowMeTheParts_Model?.model.length) {
      return data?.ShowMeTheParts_Model?.model.map((e: any) => {
        return { id: e.id, data: e.data.trim() };
      });
    } else {
      const element = data?.ShowMeTheParts_Model?.model;
      setModel((prev) => ({
        ...prev,
        value: element.id,
        label: element.data.trim() || data.id,
      }));
      setProduct(defaultState);
      setEngine(defaultState);
      return { id: element?.id, data: element?.data };
    }
  }, [year.value, make.value]);

  const getProducts = useCallback(async () => {
    if (!model.value || !licenseKey || !databaseUrl) return;
    setProduct({ ...product, isLoading: true });
    const response = await fetchPartTypes({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
      make: make.value,
      model: model.value,
    });
    if (!response) {
      setProduct({ ...product, isError: true });
    }
    const data: any = x2js?.xml2js(response?.data);
    setProduct((prev) => ({ ...prev, isLoading: false }));
    const allOption = [{ data: "ALL", id: "add_all_option_to_ymm_part_type" }];
    const partTypeData = data?.ShowMeTheParts_PartType?.parttype;

    if (!Array.isArray(partTypeData)) {
      setProduct((prev) =>
        prev.label !== partTypeData.data
          ? {
              ...prev,
              value: partTypeData.id,
              label: partTypeData.data || partTypeData.id,
            }
          : prev
      );
    } else {
      setProduct(defaultState);
    }
    const partTypeOptions: any = allOption.concat(partTypeData);

    return partTypeAllOption ? partTypeOptions : partTypeData;
  }, [year.value, make.value, model.value]);

  const getEngines = useCallback(async () => {
    if (!model.value || !licenseKey || !databaseUrl) return;
    setEngine({ ...engine, isLoading: true });
    const response = await fetchEngines({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
      make: make.value,
      model: model.value,
      product: product.value,
    });
    if (!response) {
      setEngine({ ...engine, isError: true });
    }
    const data: any = x2js?.xml2js(response?.data);
    const engineOptions = data?.ShowMeTheParts_Engine?.engine;
    if (!Array.isArray(engineOptions)) {
      setEngine((prev) =>
        prev.label !== engineOptions.data
          ? {
              ...prev,
              value: engineOptions.id,
              label: engineOptions.data || engineOptions.id,
            }
          : prev
      );
    } else {
      setEngine(defaultState);
    }
    setEngine((prev) => ({ ...prev, isLoading: false }));
    return data?.ShowMeTheParts_Engine?.engine;
  }, [year.value, make.value, model.value, product.label]);

  const handleChangeYear = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label !== year.label) {
        setYear((prev) => ({
          ...prev,
          value: data.value,
          label: data.label || data.value,
        }));
        setMake(defaultState);
        setModel(defaultState);
        setProduct(defaultState);
        setEngine(defaultState);
      }
    },
    [year]
  );

  const handleChangeMake = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label !== make.label) {
        setMake((prev) => ({
          ...prev,
          value: data.value,
          label: data.label || data.value,
        }));
        setModel(defaultState);
        setProduct(defaultState);
        setEngine(defaultState);
      }
    },
    [make]
  );

  const handleChangeModel = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label !== model.label) {
        setModel((prev) => ({
          ...prev,
          value: data.value,
          label: data.label || data.value,
        }));
        setProduct(defaultState);
        setEngine(defaultState);
      }
    },
    [model]
  );

  const handleChangeProduct = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label === "ALL") {
        setProduct({ ...defaultState, label: "ALL" });
      } else if (data.label !== product.label) {
        setProduct((prev) =>
          prev.label !== data.label
            ? { ...prev, value: data.value, label: data.label || data.value }
            : prev
        );
        setEngine(defaultState);
      }
    },
    [product]
  );

  const handleChangeEngine = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label !== engine.label) {
        setEngine((prev) =>
          prev.label !== data.label
            ? { ...prev, value: data.value, label: data.label || data.value }
            : prev
        );
      }
    },
    [engine]
  );

  const handleSearch = () => {
    setCollapsed(true);
    setSearchTerm({
      year: year.value,
      make: make.value,
      model: model.value,
      product: partTypeEnabled ? product.value : "",
      engine: engineEbabled ? engine.value : "",
    });
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      year.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      year.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      make.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      make.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      model.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      model.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
      product.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      product.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME,
      engine.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
      engine.label
    );
    localStorage.setItem(
      SptLocalStorageVariables.SPT_SELECTORS,
      JSON.stringify({
        year: year.value,
        make: make.value,
        model: model.value,
        product: product.value,
        engine: engine.value,
      })
    );
    sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
  };

  const handleClear = () => {
    setYear(defaultState);
    setMake(defaultState);
    setModel(defaultState);
    setProduct(defaultState);
    setEngine(defaultState);
    setSearchTerm({});
    handleClearSearchResult();

    const keysToRemove = [
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME,
      SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
    ];

    keysToRemove.forEach((k) => localStorage.removeItem(k));
  };

  const getGridTemplateColumn = () => {
    if (!partTypeEnabled && !engineEbabled)
      return "minmax(25%, max-content) minmax(40%, max-content) minmax(30%, max-content)";
    if (!partTypeEnabled || !engineEbabled)
      return "minmax(20%, max-content) minmax(30%, max-content) minmax(20%, max-content) minmax(25%, max-content)";
    return "minmax(110px, max-content) minmax(180px, max-content) minmax(180px, max-content) minmax(110px, 300px) minmax(110px, 1fr)";
  };

  return (
    <Wrapper>
      {apiRequestError ? (
        <div className="search-bar-active-tab-class">
          Currently unable to process part lookups. Please try again later.
        </div>
      ) : (
        <>
          <DropdownSectionStyled gridStyle={getGridTemplateColumn()}>
            <Dropdown
              value={year.label}
              onChange={handleChangeYear}
              getOptions={getYears}
              placeholder={year.isLoading ? "Loading..." : "Year"}
              searchPlaceholder="Search..."
              color={selectorsConfig?.font_color}
              fontSize={selectorsConfig?.font_size}
              fontWeight={selectorsConfig?.font_weight}
            />
            <Dropdown
              value={make.label}
              onChange={handleChangeMake}
              getOptions={getMakes}
              placeholder={make.isLoading ? "Loading..." : "Make"}
              searchPlaceholder="Search..."
              color={selectorsConfig?.font_color}
              fontSize={selectorsConfig?.font_size}
              fontWeight={selectorsConfig?.font_weight}
            />
            <Dropdown
              value={model.label}
              onChange={handleChangeModel}
              getOptions={getModels}
              placeholder={model.isLoading ? "Loading..." : "Model"}
              searchPlaceholder="Search..."
              color={selectorsConfig?.font_color}
              fontSize={selectorsConfig?.font_size}
              fontWeight={selectorsConfig?.font_weight}
            />
            {partTypeEnabled && (
              <Dropdown
                value={product.label}
                onChange={handleChangeProduct}
                getOptions={getProducts}
                placeholder={product.isLoading ? "Loading..." : "Part type"}
                searchPlaceholder="Search..."
                color={selectorsConfig?.font_color}
                fontSize={selectorsConfig?.font_size}
                fontWeight={selectorsConfig?.font_weight}
              />
            )}
            {engineEbabled && (
              <Dropdown
                value={engine.label}
                onChange={handleChangeEngine}
                getOptions={getEngines}
                placeholder={engine.isLoading ? "Loading..." : "Engine"}
                searchPlaceholder="Search..."
                color={selectorsConfig?.font_color}
                fontSize={selectorsConfig?.font_size}
                fontWeight={selectorsConfig?.font_weight}
              />
            )}
          </DropdownSectionStyled>
          <SearchButtonStyled
            type="button"
            className="search-bar-search-button-class"
            onClick={handleSearch}
            disabled={!model.value}
            backgroundColor={selectorsConfig?.search_button_background_color}
            hoverBackgroundColor={
              selectorsConfig?.search_button_hover_background_color
            }
            color={selectorsConfig?.search_button_font_color}
            fontSize={selectorsConfig?.search_button_font_size}
            fontWeight={selectorsConfig?.search_button_font_weight}
          >
            Search
          </SearchButtonStyled>
          <ClearButtonStyled
            type="button"
            className="search-bar-clear-button"
            onClick={handleClear}
            backgroundColor={selectorsConfig?.clear_button_background_color}
            hoverBackgroundColor={
              selectorsConfig?.clear_button_hover_background_color
            }
            color={selectorsConfig?.clear_button_font_color}
            fontSize={selectorsConfig?.clear_button_font_size}
            fontWeight={selectorsConfig?.clear_button_font_weight}
            disabled={!year.value}
          >
            Clear
          </ClearButtonStyled>
        </>
      )}
    </Wrapper>
  );
};

export default YearMakeModelTab;
