export const getSearchQuery = (data: { [key: string]: string }) => {
  const str = [];
  for (const p in data)
    if (data.hasOwnProperty(p) && !!data[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
    }
  return str.join('&');
};

export const setLocalStorageValue = (key: string, data: string) => {
  localStorage.setItem(key, data);
};
export const getLocalStorageValue = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? value : '';
};
