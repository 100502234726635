import { styled } from 'styled-components';

export const ProductsListStyled = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(max(300px, max(100%/5)), 1fr));
  grid-auto-rows: max-content;
  overflow-y: auto;
  padding: 0px 20px;
  @media screen and (min-width: 1024px) {
    padding: 0px 40px 0px 0px;
  }

  > div:last-child {
    height: 4px;
  }
`;
