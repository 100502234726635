import { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import { DropdownProps, Option } from "./types";
import {
  DropdownStyled,
  OptionItem,
  OptionsList,
  InputSearchStyled,
  ListStyled,
} from "./styles";
import useOnClickOutside from "../../hooks/useClickOutside";
import { ArrowDownIcon, SearchIcon } from "..";

const Dropdown: FC<DropdownProps> = ({
  searchPlaceholder,
  value,
  onChange,
  getOptions,
  placeholder,
  color,
  fontSize,
  fontWeight,
  optionsList,
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [searchItem, setSearchItem] = useState("");
  const currentOptionList = optionsList?.length ? optionsList : options;
  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    if (getOptions) {
      const fetchOptions = async () => {
        const data: any = await getOptions();
        if (Array.isArray(data)) {
          const optionsList = data
            ?.map((item: any) => ({
              value: item.id || "",
              label: item?.data || item.id || "",
            }))
            .filter((item) => !!item.value);
          setOptions(optionsList);
        } else {
          setOptions(
            [
              { value: data?.id || "", label: data?.data || data?.id || "" },
            ].filter((item) => !!item.value)
          );
        }
      };
      fetchOptions();
    }
  }, [getOptions]);

  const optionList = useMemo(() => {
    if (!searchItem) return currentOptionList;
    return currentOptionList.filter((item) =>
      item.label.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase())
    );
  }, [currentOptionList, searchItem]);

  const isListNotEmpty = isActive && !!currentOptionList?.length;

  const optionName = useMemo(() => {
    const currentOption = currentOptionList?.find(
      (item) => item.label === value
    );
    return currentOption
      ? currentOption.label || currentOption.value
      : placeholder;
  }, [value, currentOptionList, placeholder]);

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={!optionList?.length}
      ref={ref}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      <section
        onMouseDown={() => !!optionList?.length && setIsActive(!isActive)}
        className="search-bar-selector-class"
      >
        <span className="selector-text">{optionName}</span>
        <ArrowDownIcon />
      </section>
      {isListNotEmpty ? (
          <OptionsList className="optionList" collapsed={isActive}>
            <InputSearchStyled>
              <SearchIcon />
              <input
                id={"search"}
                type="search"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target?.value || "")}
                placeholder={searchPlaceholder}
              />
            </InputSearchStyled>
            <ListStyled>
              {optionList.map((item) => (
                <OptionItem
                  key={`${item.label}-${item.value}`}
                  onClick={() => {
                    onChange(item);
                    setIsActive(false);
                  }}
                  selected={value === String(item.label)}
                  className="selector-text"
                >
                  {item.label}
                </OptionItem>
              ))}
              {!optionList.length && <p>No results</p>}
            </ListStyled>
          </OptionsList>
      ) : (
        ""
      )}
    </DropdownStyled>
  );
};

export default memo(Dropdown);
