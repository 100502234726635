import { FC, memo, useEffect, useState } from "react";
import { FiltersProps } from "./types";
import { ArrowDownIcon } from "../../common/components";
import {
  Wrapper,
  ButtonSectionStyled,
  ClearButtonStyled,
  CloseButtonStyled,
  AccordionWrapperStyled,
  AccordionLabelStyled,
  CheckBoxesList,
  CheckBoxStyled,
  FilterBlock,
} from "./styled";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import { getLocalStorageValue } from "../../common/helpers";
import { DynamicSelectorTabs } from "../../common/constants/dynamicSelector";

const Filters: FC<FiltersProps> = ({
  searchAttributes,
  hideOnMobile,
  onClose,
  filters,
  setFilters,
  filterConfig,
  currentTab,
}) => {
  const [closedAccordions, setClosedAccordions] = useState<string[]>([]);

  const handleOpenAccordion = (id: string) => {
    const alreadyOpened = closedAccordions.some((item) => item == id);
    if (alreadyOpened) {
      return setClosedAccordions((prev) => [
        ...prev.filter((item) => item !== id),
      ]);
    }
    setClosedAccordions((prev) => [...prev, id]);
  };

  const handleChangeFilter = (attributeName: string, value: string) => {
    const valueExist =
      filters[attributeName] &&
      (filters[attributeName]
        ?.toLowerCase()
        .split("|")
        .some(
          (item: string) =>
            item ===
            value
              ?.toLowerCase()
              .substring(
                0,
                filters[attributeName].length < value.length
                  ? filters[attributeName].length
                  : value.length
              )
        ) ||
        value
          ?.toLowerCase()
          .includes(filters[attributeName]?.toLowerCase().split("|")[0]));

    const attributeValue = valueExist
      ? filters[attributeName]
          ?.toLowerCase()
          .split("|")
          .filter(
            (item: string) =>
              item !==
                value
                  ?.toLowerCase()
                  .substring(
                    0,
                    filters[attributeName].length < value.length
                      ? filters[attributeName].length
                      : value.length
                  ) &&
              item !==
                filters[attributeName]
                  ?.toLowerCase()
                  .split("|")
                  .filter(
                    (e) =>
                      e.includes(value?.toLowerCase()) ||
                      value?.toLowerCase().includes(e)
                  )[0]
          )
          .filter((item: string) => !!item)
          .join("|")
      : filters[attributeName]
      ? [filters[attributeName]?.toLowerCase(), value?.toLowerCase()].join("|")
      : value.toLowerCase();

    const attributeValues = filters[attributeName]
      ? [...(attributeValue ? [attributeValue] : [])].join("|")
      : value?.toLowerCase();
    const sessionFilters = localStorage.getItem(
      SptLocalStorageVariables.SPT_SELECTED_FILTERS
    )
      ? JSON.parse(
          localStorage.getItem(SptLocalStorageVariables.SPT_SELECTED_FILTERS) ||
            ""
        )
      : "";

    if (!attributeValues) {
      delete sessionFilters[attributeName];
    }

    localStorage.setItem(
      SptLocalStorageVariables.SPT_SELECTED_FILTERS,
      JSON.stringify(
        !attributeValues
          ? {
              ...sessionFilters,
              ...(!valueExist ? { [attributeName]: "undefined" } : {}),
            }
          : { ...sessionFilters, [attributeName]: attributeValues }
      )
    );

    setFilters({
      ...filters,
      [attributeName]: attributeValues,
    });
  };

  const partTypeOptionSelected = getLocalStorageValue(
    SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME
  ).toLowerCase();

  const handleClearFilters = () => {
    if (
      partTypeOptionSelected &&
      currentTab === DynamicSelectorTabs.YearMakeModal
    ) {
      setFilters({ ["part_type"]: partTypeOptionSelected.toLocaleLowerCase() });
    } else {
      setFilters({});
    }
    localStorage.removeItem(SptLocalStorageVariables.SPT_SELECTED_FILTERS);
  };

  const checkAccordionExpanded = (index: number): boolean => {
    return closedAccordions.some((item) => item == `accordion-${index + 1}`);
  };
  const checkFilterSelected = (
    attributeName: string,
    name: string
  ): boolean => {
    if (
      !filters[attributeName] ||
      filters[attributeName]?.toLowerCase() === "all"
    ) {
      return false;
    }
    return (
      !!filters[attributeName]
        ?.toLowerCase()
        ?.includes(
          name
            .toLowerCase()
            .substring(
              0,
              filters[attributeName].length < name.length
                ? filters[attributeName].length
                : name.length
            )
        ) || !!name.toLowerCase().includes(filters[attributeName].split("|")[0])
    );
  };

  useEffect(() => {
    handleClearFilters();
    localStorage.setItem(
      SptLocalStorageVariables.SPT_FILTERS,
      JSON.stringify(searchAttributes || [])
    );
  }, [searchAttributes]);

  const filteredSearchAttributes = searchAttributes?.filter((e) => {
    return e.attributeValues.filter((value) => value !== "").length;
  });

  return (
    <Wrapper hideOnMobile={hideOnMobile}>
      <AccordionWrapperStyled id="accordion-open" data-accordion="open">
        {filteredSearchAttributes?.map((item, index) => (
          <FilterBlock
            key={item.attributeName}
            hasBorder={index !== filteredSearchAttributes.length - 1}
          >
            <AccordionLabelStyled
              id={`accordion-${index + 1}`}
              isExpanded={closedAccordions.some(
                (item) => item == `accordion-${index + 1}`
              )}
              fontSize={filterConfig?.attribute_font_size}
              color={filterConfig?.attribute_font_color}
              fontWeight={filterConfig?.attribute_font_weight}
            >
              <button
                type="button"
                onClick={() => handleOpenAccordion(`accordion-${index + 1}`)}
              >
                <p>{item.attributeName.replace(/_/g, " ")}</p>
                <ArrowDownIcon color={filterConfig?.attribute_icon_color} />
              </button>
            </AccordionLabelStyled>
            <CheckBoxesList>
              {item.attributeValues
                .sort()
                .map((attribute) => (
                  <CheckBoxStyled
                    key={attribute || `No ${item.attributeName}`}
                    hideCheckBox={
                      checkAccordionExpanded(index) &&
                      !checkFilterSelected(
                        item.attributeName,
                        attribute || "undefined"
                      )
                    }
                    backgroundColor={filterConfig?.checkbox_color}
                    color={filterConfig?.checkbox_font_color}
                    fontSize={filterConfig?.checkbox_font_size}
                    fontWeight={filterConfig?.checkbox_font_weight}
                    width={filterConfig?.checkbox_width}
                    height={filterConfig?.checkbox_height}
                    hoverBackgroundColor={filterConfig?.checkbox_hover_color}
                  >
                    <div className="checkbox-wrapper">
                      <div>
                        <input
                          id={attribute || `No-${item.attributeName}`}
                          type="checkbox"
                          checked={checkFilterSelected(
                            item.attributeName,
                            attribute || "undefined"
                          )}
                          onChange={() =>
                            handleChangeFilter(
                              item.attributeName,
                              attribute || "undefined"
                            )
                          }
                        />
                      </div>
                      <label htmlFor={attribute || `No-${item.attributeName}`}>
                        {attribute || `All ${item.attributeName}s`}
                      </label>
                    </div>
                  </CheckBoxStyled>
                ))}
            </CheckBoxesList>
          </FilterBlock>
        ))}
      </AccordionWrapperStyled>
      <ButtonSectionStyled>
        <hr />
        <div>
          <ClearButtonStyled type="button" onClick={handleClearFilters}>
            Clear filters
          </ClearButtonStyled>
          <CloseButtonStyled type="button" onClick={onClose}>
            Close
          </CloseButtonStyled>
        </div>
      </ButtonSectionStyled>
    </Wrapper>
  );
};

export default memo(Filters);
