import { FC } from 'react';
import { IconProps } from '../../types';

const CloseIcon: FC<IconProps> = ({ color = '#475467' }) => {
  return (
    <svg
      viewBox="0 0 15 15"
      version="1.1"
      width="15"
      height="15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="15"
        x2="15"
        y2="1"
        stroke={color || 'currentColor'}
        strokeWidth="2"
      />
      <line
        x1="1"
        y1="1"
        x2="15"
        y2="15"
        stroke={color || 'currentColor'}
        strokeWidth="2"
      />
    </svg>
  );
};

export default CloseIcon;
