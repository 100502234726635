import { styled } from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const IconWrapper = styled.div`
  width: 125px;
  height: 100px;
  @media (min-width: 750px) {
    width: 250px;
    height: 200px;
  }
`;
