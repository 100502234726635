import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import $ from "jquery";
import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager } from "styled-components";
import { autoPartsSearch, fetchSelectorModalConfig } from "./api/autoParts";
import { Filters, ProductsList, SearchBar } from "./components";
import FilterModal from "./components/FilterModal";
import { Wrapper, ArticleStyled, DivStyled } from "./styles";
import { Loader } from "./common/components";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "./common/constants/localStorage";
import { DynamicSelectorTabs } from "./common/constants/dynamicSelector";
import { setLocalStorageValue } from "./common/helpers";

// const sessionSearchTerm = sessionStorage.getItem('SEARCH_ITEM')
//   ? JSON.parse(sessionStorage.getItem('SEARCH_ITEM') || '')
//   : {};
const sessionData = localStorage.getItem(
  SptLocalStorageVariables.SPT_SELECTED_FILTERS
)
  ? JSON.parse(
      localStorage.getItem(SptLocalStorageVariables.SPT_SELECTED_FILTERS) || ""
    )
  : {};
const storageSelectors = localStorage.getItem(
  SptLocalStorageVariables.SPT_SELECTORS
)
  ? JSON.parse(
      localStorage.getItem(SptLocalStorageVariables.SPT_SELECTORS) || ""
    )
  : {};

const perPage = 20;

const App = ({
  store,
  currency,
}: {
  store: string | null;
  currency: string | null;
}) => {
  const [searchTerm, setSearchTerm] = useState(storageSelectors);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: string }>(
    sessionData
  );
  const [currentTab, setCurrentTab] = useState<DynamicSelectorTabs>(
    DynamicSelectorTabs.YearMakeModal
  );
  const [data, setData] = useState<any>(null);
  const [count, setCount] = useState<number>(perPage);
  const [selectorModalConfig, setSelectorModalConfig] = useState<any>(null);
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalWindow, setShowModalWindow] = useState<boolean>(false);
  console.log($);

  const dataConverting = (data: any) => {
    const newFormat = data.map((e: any) => {
      return {
        [e?.part_key]: {
          brand: e?.applications[0].supplier,
          configurations: e.applications.map((el: any) => {
            return {
              comment: el.comment,
              location: el.location,
              application: el.application,
            };
          }),
        },
      };
    });
    const newFormatObject = Object.assign({}, ...newFormat);
    setLocalStorageValue(
      SelectorsLocalStorage.CURRENT_FITMENT_PART_DATA,
      JSON.stringify(newFormatObject)
    );
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await autoPartsSearch(store, searchTerm);
    if (!response) {
      setSearchError(true);
    }
    setData(response?.data);
    if (response?.data?.data?.data?.products?.length) {
      dataConverting(response.data.data.data.products);
    } else {
      setLocalStorageValue(
        SelectorsLocalStorage.CURRENT_FITMENT_PART_DATA,
        JSON.stringify({})
      );
    }
    setIsLoading(false);
  }, [selectorModalConfig, searchTerm]);

  const Observe = (sel: string, opt: any, cb: (m: MutationRecord) => void) => {
    const Obs = new MutationObserver((m) => [...m].forEach(cb));
    document.querySelectorAll(sel).forEach((el) => Obs.observe(el, opt));
  };

  Observe(
    "#spt-react-app",
    {
      attributesList: ["style"], // Only the "style" attribute
      attributeOldValue: true, // Report also the oldValue
    },
    (m: MutationRecord) => {
      const isDisplayBlock = m?.oldValue?.includes("none");
      setShowModalWindow(!!isDisplayBlock);
      return !!isDisplayBlock;
    }
  );

  useLayoutEffect(() => {
    const getSelectorModalConfig = async () => {
      setIsConfigLoading(true);
      const response = await fetchSelectorModalConfig(store);
      setSelectorModalConfig(response.data);
      setIsConfigLoading(false);
    };
    getSelectorModalConfig();
    const storageProducts = localStorage.getItem(
      SptLocalStorageVariables.SPT_PRODUCTS
    )
      ? JSON.parse(
          localStorage.getItem(SptLocalStorageVariables.SPT_PRODUCTS) || ""
        )
      : [];
    const storageFilters = localStorage.getItem(
      SptLocalStorageVariables.SPT_FILTERS
    )
      ? JSON.parse(
          localStorage.getItem(SptLocalStorageVariables.SPT_FILTERS) || ""
        )
      : [];
    setData({
      data: {
        data: { products: storageProducts, searchAttributes: storageFilters },
      },
    });
  }, []);

  useEffect(() => {
    if (store && !!Object.keys(searchTerm)?.length) {
      fetchData();
    }
  }, [store, searchTerm]);

  useEffect(() => {
    if (filters?.part_type?.includes("all")) {
      setFilters({ ...filters, part_type: "" });
    }
  }, [filters]);

  const filteredProducts = useMemo(() => {
    if (!data?.data?.data?.products.length) return [];
    localStorage.setItem(
      SptLocalStorageVariables.SPT_PRODUCTS,
      JSON.stringify(data?.data?.data?.products || [])
    );
    return (data?.data?.data?.products).filter((item: any) => {
      const result = Object.keys(filters)?.filter((attribute: any) => {
        if (filters[attribute].toLowerCase() === "all" || !filters[attribute]) {
          return false;
        }

        return filters[attribute]
          .toLowerCase()
          ?.includes(
            item.applications[0][attribute]
              ?.toLowerCase()
              .substring(
                0,
                filters[attribute].length <
                  item.applications[0][attribute].length
                  ? filters[attribute].length
                  : item.applications[0][attribute].length
              ) || "undefined"
          ) ||
          item.applications
            .map((e: any) =>
              e[attribute]
                ?.toLowerCase()
                .includes(filters[attribute].split("|")[0])
            )
            .filter((e: boolean) => e === true).length
          ? true
          : false;
      });
      return (
        result.length ===
        Object.values(filters)?.filter((item) => !!item && item !== "all")
          .length
      );
    });
  }, [data?.data?.data?.products, filters, searchTerm]);

  const handleShowFilters = () => {
    setShowFilters((prev) => !prev);
  };

  const handleClearSearchResult = () => {
    setData(null);
  };

  useEffect(() => {
    setCount(perPage);
  }, [fetchData]);

  const isFiltersShown = !!data?.data?.data?.products?.length;

  return (
    <DivStyled
      id="spt-react-app"
      style={{ display: "none" }}
      fontFamily={selectorModalConfig?.search_bar_config?.font_family}
    >
      {!!showModalWindow && (
        <StyleSheetManager shouldForwardProp={isPropValid}>
          {isConfigLoading ? (
            <Loader />
          ) : (
            <Wrapper className="main-wrapper">
              {isLoading && <Loader />}
              <SearchBar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchBarConfig={selectorModalConfig?.search_bar_config}
                selectorsConfig={selectorModalConfig?.selectors_config}
                licenseKey={selectorModalConfig?.license_key}
                databaseUrl={selectorModalConfig?.database_url}
                vinLookupEnabled={selectorModalConfig?.vin_lookup_enabled}
                licensePlateLookupEnabled={
                  selectorModalConfig?.license_plate_lookup_enabled
                }
                partTypeAllOption={
                  selectorModalConfig?.add_all_option_to_ymm_part_type
                }
                partTypeEnabled={selectorModalConfig?.part_type_enabled}
                engineEbabled={selectorModalConfig?.engine_enabled}
                setData={setData}
                setTab={setCurrentTab}
                searchError={searchError}
                store={store}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                handleShowFilters={handleShowFilters}
                handleClearSearchResult={handleClearSearchResult}
              />
              {!!Object.keys(searchTerm).length && !searchError && (
                <ArticleStyled>
                  {isFiltersShown && (
                    <Filters
                      searchAttributes={data?.data?.data?.searchAttributes}
                      hideOnMobile={true}
                      onClose={handleShowFilters}
                      filters={filters}
                      setFilters={setFilters}
                      filterConfig={selectorModalConfig?.filter_config}
                      currentTab={currentTab}
                    />
                  )}
                  <ProductsList
                    products={filteredProducts}
                    count={count}
                    setCount={setCount}
                    perPage={perPage}
                    productCardConfig={selectorModalConfig?.product_card_config}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    addToCart={selectorModalConfig?.add_to_cart_enabled}
                    currency={currency}
                    isLoading={isLoading}
                  />
                </ArticleStyled>
              )}
              <FilterModal opened={showFilters}>
                <Filters
                  searchAttributes={data?.data?.data?.searchAttributes}
                  hideOnMobile={false}
                  onClose={handleShowFilters}
                  filters={filters}
                  setFilters={setFilters}
                  filterConfig={selectorModalConfig?.filter_config}
                  currentTab={currentTab}
                />
              </FilterModal>
            </Wrapper>
          )}
        </StyleSheetManager>
      )}
    </DivStyled>
  );
};

export default App;
