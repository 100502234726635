export const MinusIcon = ({ color = "#0064FE" }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="2"
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1"
        x2="16"
        y2="1"
        stroke={color}
        stroke-width="2"
        className="icon"
      />
    </svg>
  );
};
