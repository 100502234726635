import { ChangeEvent, FC, useCallback, useState } from "react";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../common/helpers";
import X2JS from "x2js";
import { LicensePlateProps } from "./types";
import { Wrapper, SearchButtonStyled } from "./styled";
import { Input, Loader } from "../../common/components";
import {
  autoPartsSearch,
  fetchSMTPData,
} from "../../api/autoParts";
import { statesList } from "../../common/constants/statesList";

const storageLicensePlate =
  getLocalStorageValue(SelectorsLocalStorage.CURRENT_LICENSE_PLATE) || "";

const LicensePlate: FC<LicensePlateProps> = ({
  selectorsConfig,
  setData,
  licenseKey,
  databaseUrl,
  store,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [licensePlate, setLicensePlate] = useState<string>(storageLicensePlate);
  const [stateId, setStateId] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");

  const x2js = new X2JS();

  const handleChangeLicensePlate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLicensePlate(e.target.value);
    },
    [licensePlate]
  );

  const handleSearch = async () => {
    setIsLoading(true);
    setLocalStorageValue(
      SelectorsLocalStorage.CURRENT_LICENSE_PLATE,
      licensePlate
    );
    if (!licenseKey || !databaseUrl) return;
    const smtpResponse = await fetchSMTPData({
      id: licenseKey,
      url: databaseUrl,
      licensePlate,
      stateId: stateId,
    });
    const data: any = x2js?.xml2js(smtpResponse?.data);
    const smtpResult = data?.ShowMeThePartsVinApps.vinapps;
    const response: any = await autoPartsSearch(store, {
      year: smtpResult.year,
      make: smtpResult.makeid,
      model: smtpResult.modelid,
      engine: smtpResult.engineid,
    });
    setData(response.data);
    sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
    setIsLoading(false);
  };

  const handleChange = ({ value, label }: { value: string; label: string }) => {
    setStateId(value);
    setSelectedLabel(label);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Input
        id="licensePlate"
        value={licensePlate}
        onChange={handleChangeLicensePlate}
        placeholder="License plate"
        color={selectorsConfig?.font_color}
        fontSize={selectorsConfig?.font_size}
        fontWeight={selectorsConfig?.font_weight}
      />
      <Dropdown
        value={selectedLabel}
        onChange={({ value, label }) => handleChange({ value, label })}
        optionsList={statesList}
        placeholder={"Select License Plate State"}
        searchPlaceholder="Search..."
        color={selectorsConfig?.font_color}
        fontSize={selectorsConfig?.font_size}
        fontWeight={selectorsConfig?.font_weight}
      />
      <SearchButtonStyled
        type="button"
        className="search-bar-search-button-class"
        onClick={handleSearch}
        disabled={!licensePlate || !stateId}
        backgroundColor={selectorsConfig?.search_button_background_color}
        hoverBackgroundColor={
          selectorsConfig?.search_button_hover_background_color
        }
        color={selectorsConfig?.search_button_font_color}
        fontSize={selectorsConfig?.search_button_font_size}
        fontWeight={selectorsConfig?.search_button_font_weight}
      >
        Search
      </SearchButtonStyled>
    </Wrapper>
  );
};

export default LicensePlate;
