export const PlusIcon = ({ color = "#0064FE" }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
    >
      <line
        x1="8"
        y1="4.37114e-08"
        x2="8"
        y2="16"
        stroke={color}
        stroke-width="2"
      />
      <line y1="8" x2="16" y2="8" stroke={color} stroke-width="2" />
    </svg>
  );
};
