import { FC } from 'react';
import { LoaderStyled } from './styled';

const Loader: FC = () => {
  return (
    <LoaderStyled>
      <div id="loader"></div>
    </LoaderStyled>
  );
};

export default Loader;
