import { styled } from 'styled-components';

export const LoaderStyled = styled.div`
  position: absolute;
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  z-index: 1000000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background: transparent !important;
  > div {
    display: block !important;
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m) !important;
    background: #0119d6 !important;
    -webkit-mask-composite: source-out;
    mask-composite: subtract !important;
    animation: l3 1s infinite linear;

    @keyframes l3 {
      to {
        transform: rotate(1turn);
      }
    }
  }
`;
