import { FC, memo } from 'react';
import { InputStyled } from './styled';
import { InputProps } from './types';

const Input: FC<InputProps> = ({
  id,
  label,
  maxLength,
  minLength,
  value,
  color,
  fontSize,
  fontWeight,
  ...restProps
}) => {
  const showMaxLengthTip = !minLength && maxLength;
  return (
    <InputStyled
      spanError={maxLength !== value?.length}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        {...restProps}
      />
      {showMaxLengthTip && (
        <span>{`${value ? value.length : 0}/${maxLength}`}</span>
      )}
    </InputStyled>
  );
};

export default memo(Input);
