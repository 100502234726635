import { FC, useCallback, useEffect, useState } from "react";
import { CardProps } from "./types";
import {
  AddButton,
  BrandName,
  BrandTypography,
  CardHeader,
  CardLink,
  CardStyled,
  CurrencyName,
  FlexContainer,
  FlexItem,
  HeaderWrapper,
  ImageWrapper,
  InfoWrapper,
  LinkWrapper,
  PartFit,
  PartFitText,
  QuantityWrapper,
  SectionHeader,
  SliderButtonsWrapper,
  SliderContainer,
  TextWrapper,
  VariantDescription,
  VariantHeading,
  VariationCard,
  VariationsWrapper,
  VariationWrapper,
  Wrapper,
} from "./styled";
import { addToCartRequest } from "../../api/autoParts";
import { useGlobalContext } from "../../context";
import { GreenCheck } from "../../assets/greenCheck";
import QuantityPicker from "./QuantityPicker";
// import { Applications } from "../../common/types";
import useEmblaCarousel from "embla-carousel-react";
import SliderButton from "./SliderButton";

const HorizontalCard: FC<CardProps> = ({ product, addToCart, currency }) => {
  const [addedToCart, setAddedToCart] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const imageURL = product.imageUrl?.length ? product.imageUrl[0].src : "";
  const productUrl = `https://${product.productUrl}`;
  const fitmentLabel = document.getElementById("fitment-label-id")?.innerText;
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);
  const { state } = useGlobalContext();
  const { year, make, model, selectorModalConfig } = state;
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    loop: false,
    slidesToScroll: 1,
  });

  const productCardConfig = selectorModalConfig?.product_card_config;
  const applicationsLabel = selectorModalConfig?.applications_label;
  const haveCorrectCards = product?.applications?.filter(
    (e) => e.application || e.comment || e.location || e.submodel
  );

  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const updateButtonStates = useCallback(() => {
    if (!emblaApi) return;
    setIsAtStart(emblaApi.canScrollPrev() === false); // True if at the start
    setIsAtEnd(emblaApi.canScrollNext() === false); // True if at the end
  }, [emblaApi]);

  // Scroll to the next slide
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // Scroll to the previous slide
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      updateButtonStates();
      emblaApi.on("scroll", updateButtonStates);
    }

    return () => {
      if (emblaApi) {
        emblaApi.off("scroll", updateButtonStates);
      }
    };
  }, [emblaApi, updateButtonStates]);

  const currentCurrency = product?.pricing_options?.filter(
    (e) => e.market === currency
  );
  const priceFormatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currentCurrency.length ? currentCurrency[0]?.currencyCode ?? "USD" : "USD",
  });

  const handleChangeQuantity = (quantity: number) => {
    setSelectedQuantity(quantity);
  };

  const addToCartClick = async () => {
    if (product.variandId && product.quantity) {
      const substring = product.variandId.split("/");
      const id = substring[substring.length - 1];
      let formData = {
        items: [
          {
            id: id,
            quantity: selectedQuantity,
            properties: {
              Fitment: fitmentLabel,
            },
          },
        ],
      };
      setAddToCartLoading(true);
      const resp = await addToCartRequest({ formData });
      setAddToCartLoading(false);
      if (resp) {
        setAddedToCart(true);
      }
    }
  };

  useEffect(() => {
    if (addedToCart) {
      const timeoutId = setTimeout(() => {
        setAddedToCart(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [addedToCart]);

  return (
    <CardStyled
      backgroundColor={productCardConfig?.card_background_color}
      borderWidth={productCardConfig?.card_border_width}
      borderColor={productCardConfig?.card_border_color}
      borderRadius={productCardConfig?.card_border_radius}
    >
      <CardLink href={productUrl}>
        <ImageWrapper>
          <img src={imageURL} alt={product.name} />
        </ImageWrapper>
      </CardLink>

      <InfoWrapper>
        <HeaderWrapper>
          <div>
            <LinkWrapper href={productUrl}>
              <CardHeader
                color={productCardConfig?.card_product_name_font_color}
                fontSize={productCardConfig?.card_product_name_font_size}
                fontWeight={productCardConfig?.card_product_name_font_weight}
              >
                {product.name}
              </CardHeader>
            </LinkWrapper>

            {/* Brand name */}
            {product.applications && product.applications[0].brand ? (
              <TextWrapper>
                <BrandTypography>
                  Brand:
                  <BrandName>&nbsp;{product.applications[0].brand}</BrandName>
                </BrandTypography>
              </TextWrapper>
            ) : (
              <TextWrapper>
                <BrandTypography>
                  <BrandName />
                </BrandTypography>
              </TextWrapper>
            )}
            {/* Part will fit */}
            <PartFit>
              <GreenCheck />
              <PartFitText>
                Part will fit {year.label} {make.label} {model.label}
              </PartFitText>
            </PartFit>
          </div>
          {/* Price, Quantity and add to Cart */}
          {addToCart ? (
            <div>
              {product.quantity ? (
                <Wrapper>
                  <CardHeader
                    color={productCardConfig?.card_product_price_font_color}
                    fontSize={productCardConfig?.card_product_price_font_size}
                    fontWeight={
                      productCardConfig?.card_product_price_font_weight
                    }
                  >
                    {priceFormatter.format(
                      currentCurrency
                        ? currentCurrency[0]?.price
                        : Number(product.price)
                    )}
                  </CardHeader>
                  <QuantityWrapper>
                    <QuantityPicker
                      quantity={selectedQuantity}
                      handleChangeQuantity={handleChangeQuantity}
                      maxQuantity={product.quantity}
                    />
                    <AddButton
                      onClick={addToCartClick}
                      aria-disabled={addToCartLoading}
                    >
                      {addedToCart
                        ? "Added!"
                        : addToCartLoading
                        ? "Adding..."
                        : "Add to Cart"}
                    </AddButton>
                  </QuantityWrapper>
                </Wrapper>
              ) : (
                <CurrencyName>Sold out</CurrencyName>
              )}
            </div>
          ) : (
            <></>
          )}
        </HeaderWrapper>

        {/* Variants section */}
        {haveCorrectCards?.length && haveCorrectCards.length > 4 ? (
          <VariationsWrapper>
            <SectionHeader>{`${
              applicationsLabel ? applicationsLabel : "Variations"
            } (${haveCorrectCards?.length})`}</SectionHeader>
            <SliderButtonsWrapper>
              <SliderButton prev onClick={scrollPrev} disabled={isAtStart} />
              <SliderButton onClick={scrollNext} disabled={isAtEnd} />
            </SliderButtonsWrapper>
          </VariationsWrapper>
        ) : (
          <>
            {haveCorrectCards?.length ? (
              <VariationsWrapper>
                <SectionHeader>{`${
                  applicationsLabel ? applicationsLabel : "Variations"
                } (${haveCorrectCards?.length})`}</SectionHeader>
              </VariationsWrapper>
            ) : (
              <></>
            )}
          </>
        )}

        {haveCorrectCards?.length ? (
          haveCorrectCards.length > 4 ? (
            <SliderContainer ref={emblaRef}>
              <FlexContainer>
                {haveCorrectCards.map((a, idx) => (
                  <FlexItem>
                    {
                      <VariationCard key={`${a.comment}+${idx}`}>
                        {a?.location && (
                          <div>
                            <VariantHeading>Location:</VariantHeading>
                            <VariantDescription>
                              &nbsp;{a.location}
                            </VariantDescription>
                          </div>
                        )}
                        {a.application && (
                          <div>
                            <VariantHeading>Application:</VariantHeading>
                            <VariantDescription>
                              &nbsp;{a.application}
                            </VariantDescription>
                          </div>
                        )}
                        {a.submodel && (
                          <div>
                            <VariantHeading>Submodel:</VariantHeading>
                            <VariantDescription>
                              &nbsp;{a.submodel}
                            </VariantDescription>
                          </div>
                        )}
                        {a.comment && (
                          <div>
                            <VariantHeading>Comment:</VariantHeading>
                            <VariantDescription>
                              &nbsp;{a.comment}
                            </VariantDescription>
                          </div>
                        )}
                      </VariationCard>
                    }
                  </FlexItem>
                ))}
              </FlexContainer>
            </SliderContainer>
          ) : (
            <VariationWrapper>
              {haveCorrectCards?.map((e, idx) => (
                <VariationCard
                  key={`${e.comment}+${idx}`}
                  backgroundColor={
                    productCardConfig?.card_product_applications_background
                  }
                >
                  {e?.location && (
                    <div>
                      <VariantHeading
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        Location:
                      </VariantHeading>
                      <VariantDescription
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        &nbsp;{e.location}
                      </VariantDescription>
                    </div>
                  )}
                  {e.application && (
                    <div>
                      <VariantHeading
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        Application:
                      </VariantHeading>
                      <VariantDescription
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        &nbsp;{e.application}
                      </VariantDescription>
                    </div>
                  )}
                  {e.submodel && (
                    <div>
                      <VariantHeading
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        Submodel:
                      </VariantHeading>
                      <VariantDescription
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        &nbsp;{e.submodel}
                      </VariantDescription>
                    </div>
                  )}
                  {e.comment && (
                    <div>
                      <VariantHeading
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        Comment:
                      </VariantHeading>
                      <VariantDescription
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                      >
                        &nbsp;{e.comment}
                      </VariantDescription>
                    </div>
                  )}
                </VariationCard>
              ))}
            </VariationWrapper>
          )
        ) : (
          <></>
        )}
      </InfoWrapper>
    </CardStyled>
  );
};

export default HorizontalCard;
