import { FC } from 'react';
import { FilterModalProps } from './types';
import { FilterModalStyled } from './styled';

const FilterModal: FC<FilterModalProps> = ({ children, opened }) => {
  return (
    <FilterModalStyled opened={opened}>
      <div>{children}</div>
    </FilterModalStyled>
  );
};

export default FilterModal;
