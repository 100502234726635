import { styled } from 'styled-components';
import { FilterModalStyledProps } from './types';

export const FilterModalStyled = styled.div<FilterModalStyledProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  align-items: center;
  justify-content: center;
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  background-color: #18181bbf;

  > div {
    position: absolute;
    bottom: 0;
    height: calc(100% - 30px);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    z-index: 10;
    background-color: #ffffff;
    width: 100%;
  }
`;
