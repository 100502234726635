import axios from "axios";
import CryptoJS from "crypto-js";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_SPT_API_URL,
});

const generateHash = () => {
  let store;
  const apiSigningKey = import.meta.env.VITE_API_SIGNING_KEY;
  
  const shopifyDomainElement = document
    ?.evaluate(
      '//div[@shopifydomain]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (shopifyDomainElement) {
    store = shopifyDomainElement?.getAttribute('shopifydomain');
  }
  console.log('Store');
  console.log(store);
  console.log('Signing Key');
  console.log(apiSigningKey);
  return CryptoJS.SHA256(store + apiSigningKey).toString(CryptoJS.enc.Hex);
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    const token = generateHash();
    console.log(token);
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
