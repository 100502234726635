import { FC, useCallback, useMemo, useState } from "react";
import X2JS from "x2js";
import { fetchProductsByVin } from "../../api/showMeTheParts";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../common/helpers";
import { VINProps } from "./types";
import { Wrapper, SearchButtonStyled } from "./styled";
import { Input } from "../../common/components";

const storageVin =
  getLocalStorageValue(SelectorsLocalStorage.SELECTED_VIN_VALUE_NAME) || "";

const VIN: FC<VINProps> = ({
  selectorsConfig,
  setSearchTerm,
  licenseKey,
  databaseUrl,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [vin, setVin] = useState<string>(storageVin);
  const [vinData, setVinData] = useState<any>(null);
  const [product, setProduct] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const x2js = new X2JS();

  const options = useMemo(() => {
    if (!vinData?.products?.productdata.length) {
      const item = vinData?.products?.productdata;
      if (item?.id) {
        return [{ value: item?.id, label: item?.data }];
      } else {
        return [];
      }
    }
    return vinData?.products?.productdata.map((item: any) => ({
      value: item.id,
      label: item.data,
    }));
  }, [vinData]);

  const getProducts = useCallback(async () => {
    if (!licenseKey || !databaseUrl) return;
    setIsLoading(true);
    const response = await fetchProductsByVin({
      id: licenseKey,
      url: databaseUrl,
      vin: vin,
    });

    const data: any = x2js?.xml2js(response?.data);

    setVinData(data?.ShowMeTheParts_App_Products?.app?.app_data);
    setIsLoading(false);
  }, [licenseKey, databaseUrl, vin]);

  const handleChangeVin = useCallback(
    (value: string) => {
      if (value !== vin) {
        setVin(value);
      }
    },
    [vin]
  );

  const handleSearch = () => {
    if (vinData) {
      sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
      setLocalStorageValue(SelectorsLocalStorage.SELECTED_VIN_VALUE_NAME, vin);
      setSearchTerm({
        year: vinData.yearid,
        make: vinData.makeid,
        model: vinData.modelid,
        product: product,
        engine: vinData.engineid,
      });
    }
  };

  const vinLabel = [vinData?.year, vinData?.make, vinData?.model]
    .filter((item) => !!item)
    .join(" ");

  return (
    <Wrapper>
      <div>
        <Input
          id="VIN"
          label={`Vehicle: ${vinLabel}`}
          value={vin}
          onChange={(e) => {
            handleChangeVin(e.target.value);
          }}
          maxLength={17}
          color={selectorsConfig?.font_color}
          fontSize={selectorsConfig?.font_size}
          fontWeight={selectorsConfig?.font_weight}
        />
        <SearchButtonStyled
          className="search-bar-search-button-class"
          type="button"
          onClick={getProducts}
          disabled={vin?.length !== 17 || isLoading}
          backgroundColor={selectorsConfig?.search_button_background_color}
          hoverBackgroundColor={
            selectorsConfig?.search_button_hover_background_color
          }
          color={selectorsConfig?.search_button_font_color}
          fontSize={selectorsConfig?.search_button_font_size}
          fontWeight={selectorsConfig?.search_button_font_weight}
        >
          Find Products
        </SearchButtonStyled>
      </div>
      <div>
        <Dropdown
          value={selectedValue}
          onChange={({ value, label }) => {
            setProduct(value);
            setSelectedValue(label);
          }}
          optionsList={options}
          placeholder={isLoading ? "Loading..." : "Select Product"}
          searchPlaceholder="Search..."
          disabled={!options.length}
          color={selectorsConfig?.font_color}
          fontSize={selectorsConfig?.font_size}
          fontWeight={selectorsConfig?.font_weight}
        />
        <SearchButtonStyled
          type="button"
          className="search-bar-search-button-class"
          onClick={handleSearch}
          disabled={!product}
          backgroundColor={selectorsConfig?.search_button_background_color}
          hoverBackgroundColor={
            selectorsConfig?.search_button_hover_background_color
          }
          color={selectorsConfig?.search_button_font_color}
          fontSize={selectorsConfig?.search_button_font_size}
          fontWeight={selectorsConfig?.search_button_font_weight}
        >
          Search
        </SearchButtonStyled>
      </div>
    </Wrapper>
  );
};

export default VIN;
