import { EmptyStateIcon } from "../../common/components";
import { IconWrapper, Wrapper } from "./styled";

const EmptyStateComponent = ({
  color,
  isLoading,
}: {
  color: string;
  isLoading: boolean;
}) => {
  return (
    <Wrapper>
      <IconWrapper>
        <EmptyStateIcon color={color} />
      </IconWrapper>
      <h1>{isLoading ? "Searching..." : "No products found"}</h1>
    </Wrapper>
  );
};

export default EmptyStateComponent;
